<template>
  <div class="content_center Header">
    <div class="Header-left">
      <h1 class="title">
        <router-link to="/">{{ globalOptions.title }}</router-link>
      </h1>
      <p class="name_en">{{ globalOptions.subTitleEn }}</p>
      <!-- <div class="sub_title">
        <p class="name_cn">{{ globalOptions.subTitle }}</p>
        <p class="name_en">{{ globalOptions.subTitleEn }}</p>
      </div> -->
    </div>
    <div class="Header-right">
      <div class="serve">
        <p>{{ globalOptions.phone }}</p>
        <p>{{ globalOptions.serveTime }}服务热线</p>
      </div>
      <!-- <div class="btn">立即咨询</div> -->
    </div>
  </div>
</template>

<script>
  import { mapState  } from 'vuex'
  export default {
    name: 'Header',
    data() {
      return {
      }
    },
    computed: {
      ...mapState ({
          globalOptions: state => state.globalOptions,
      }),
    }
  }
</script>

<style lang="scss" scoped>
.Header{
  // height: 120px;
  display: flex;
  justify-content: space-between;
  padding: 25px 0 0;
  margin: 0 auto;
  box-sizing: border-box;
  &-left{
    display: flex;
    flex-direction: column;
    .title{
      a{
        font-size: 40px;
        color: #fff;
      }
    }
    .name_en{
      font-size: 20px;
      color: #fff;
    }
    // .sub_title{
    //   padding-left: 15px;
    //   margin: 5px 0;
    //   border-left: 1px solid #C1C1C9;
    //   .name_cn{
    //     font-size: 30px;
    //     margin-bottom: 10px;
    //     color: #fff;
    //   }
    // }
  }
  &-right{
    display: flex;
    justify-content: end;
    padding: 10px 0;
    .serve{
      text-align: right;
      p:first-child{
        font-size: 24px;
        color: #fff;
      }
      p:last-child{
        color: #fff;
      }
    }
    .btn{
      width: 138px;
      height: 48px;
      line-height: 48px;
      margin-left: 40px;
      background-color: $theme_1;
      font-size: 16px;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 1365px) {
  .Header {
    &.content_center{
      width: 1200px;
    }
    &-left{
      .title{
        a{
          font-size: 36px;
        }
      }
      .name_en{
        font-size: 14px;
      }
      // .sub_title{
      //   .name_cn{
      //     font-size: 16px;
      //   }
      //   .name_en{
      //     font-size: 14px;
      //   }
      // }
    }
    &-right{
      .serve{
        p:first-child{
          font-size: 18px;
        }
        p:last-child{
          font-size: 12px;
        }
      }
      .btn{
        width: 110px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
      }
    }
  }
}
</style>