<template>
  <div class="LoginRegister">
    <div class="LoginRegister-header">
      <Header />
    </div>
    <div class="LoginRegister-content">
      <div class="LoginRegister-content-form">
        <div class="top">
          <div class="left">
            <span>{{ options.titleEn }}</span>
            <span>{{ options.titleCn }}</span>
          </div>
          <!-- <div class="right" v-if="isLogin">
            <el-button type="primary" @click="goCustoms">关务系统登录<span class="icon_continer"></span></el-button>
          </div> -->
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="center-form">
          <el-form-item prop="username">
            <el-input v-model="ruleForm.username" autocomplete="off" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="密码"></el-input>
          </el-form-item>
          <el-form-item prop="checkPassword" v-if="isLogin">
            <el-input type="password" v-model="ruleForm.checkPassword" autocomplete="off" placeholder="确认密码"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="submit_btn" @click="submitForm('ruleForm')">{{ options.btnText }}</div>
          </el-form-item>
        </el-form>
        <!-- <div class="bottom">
          {{ text }}&nbsp;<router-link :to="options.goRouter">{{ options.goText }}</router-link>
        </div> -->
      </div>
    </div>
    <Footer :isHide="true" />
  </div>
</template>

<script>
  import Header from '@/components/Header.vue';
  export default {
    name: 'LoginRegister',
    components: {
      Header,
    },
    data() {
      var validatePassword = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPassword !== '') {
            this.$refs.ruleForm.validateField('checkPassword');
          }
          callback();
        }
      };
      var validateCheckPassword = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        isLogin: true,
        options: {
          titleEn: 'Log in',
          titleCn: '登录信息',
          btnText: '立即登录',
          goText: '立即注册',
          goRouter: '/register',
        }, 
        ruleForm: {
          username: '',
          password: '',
          checkPassword: '',
        },
        rules: {
          password: [
            { validator: validatePassword, trigger: 'blur' }
          ],
          checkPassword: [
            { validator: validateCheckPassword, trigger: 'blur' }
          ],
          username: [
            { required: true, message: '用户名不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    computed: {
      text() {
        return this.isLogin ? '还没有账号？' : '已有账号？'; 
      }
    },
    watch: {
      '$route.name': {
        immediate: true,
        handler(name) {
          this.isLogin = name === 'Login';
          if(name === 'Login') {
            this.options = {
              titleEn: 'Log in',
              titleCn: '登录信息',
              btnText: '立即登录',
              goText: '立即注册',
              goRouter: '/register',
            }
          } else {
            this.options = {
              titleEn: 'Sign up',
              titleCn: '注册信息',
              btnText: '提交注册',
              goText: '立即登录',
              goRouter: '/login',
            }
          }
        }
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      goCustoms() {
        window.open(`${this.$customsUrl}`, '_blank');
      }
    },
  }
</script>

<style lang="scss" scoped>
.LoginRegister{
  width: 100vw;
  height: 100vh;
  background: url('~@/assets/images/login_register_bg.png') center center no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &-header{
    padding-bottom: 30px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &-content{
    flex: 1;
    position: relative;
    min-height: 615px;
    &-form{
      width: 405px;
      max-height: 535px;
      padding: 60px 25px 25px;
      position: absolute;
      top: 50%;
      right: 20vw;
      transform: translateY(-50%);
      background-color: #FFFFFF;
      box-shadow: 0px 6px 10px 0px rgba(52,132,251,0.07);
      border-radius: 8px;
      box-sizing: border-box;
      .top{
        display: flex;
        justify-content: space-between;
        .left{
          span{
            color: #4A4A53;
            &:first-child{
              margin-right: 8px;
              font-size: 36px;
              font-weight: 700;
              color: #3484FB;
            }
          }
        }
        .right{
          ::v-deep(.el-button){
            margin-top: 10px;
            span{
              display: flex;
              line-height: 16px;
              color: #fff;
              .icon_continer{
                width: 16px;
                height: 16px;
                margin-left: 5px;
                background: url('~@/assets/images/icon_right.png') right center no-repeat;
              }
            }
          }
        }
      }
      ::v-deep(.center-form){
        margin: 30px 0;
        .el-form-item{
          .el-form-item__content{
            line-height: 56px;
            .el-input{
              .el-input__inner{
                height: 56px;
                line-height: 56px;
                border: none;
                background-color: #F8F8FB;
              }
            }
          }
          .submit_btn{
            width: 100%;
            height: 54px;
            line-height: 54px;
            margin-top: 30px;
            background-color: #3484FB;
            border-radius: 4px;
            font-size: 24px;
            font-weight: 700;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;
            &:hover{
              background-color: rgba(52, 132, 251, 0.8);
            }
          }
        }
      }
      .bottom{
        display: flex;
        justify-content: center;
        text-align: center;
        color: #4A4A53;
        a{
          color: #3484FB;
        }
      }
    }
  }
  ::v-deep(.Footer){
    padding-bottom: 50px;
    background-color: rgba(0, 0, 0, 0.4);
  }
  // position: relative;
  // &-bg{
  //   width: 100%;
  //   height: 100%;
  //   img{
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
}
</style>